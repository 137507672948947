<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Wellness Accounts and Disease Management Program</h1>

      <p>
        Employer-sponsored Wellness Accounts and Disease Management Program are
        offered as an incentive to promote employee health and activity-based
        programs. These accounts are unique to every employer.
      </p>
    </div>

    <InfoDropdown title="Wellness Accounts" :headerLvl="2">
      <p>
        Wellness programs can take many forms and may not even be called a
        “wellness program.” They may cover only employees and/or other family
        members. A comprehensive employer-sponsored wellness programs might
        offer a system of coordinated health-related communications,
        assessments, and incentives intended to raise health awareness and
        promote positive health behaviors.
      </p>

      <p>
        Wellness programs might also use one, some, or all of the following
        tools to promote wellness and decrease health plan costs:
      </p>
      <ul>
        <li>health-related newsletters and informative material</li>
        <li>lunch-hour sessions on first aid, self-care, or healthy eating</li>
        <li>health risk assessments of willing employees</li>
        <li>
          personal consultations with case managers regarding nutrition,
          lifestyle changes, and related issues
        </li>
        <li>blood pressure and cholesterol screening</li>
        <li>optional or compulsory smoking-cessation programs</li>
        <li>weight-loss programs</li>
        <li>flu shots or other vaccinations</li>
        <li>on-site gyms or other exercise facilities</li>
        <li>payment of health club dues</li>
        <li>diagnostic testing; and</li>
        <li>
          financial incentives to encourage participation or achievement of
          specific wellness standards
        </li>
      </ul>

      <p>
        Some offer rewards to employees who participate or impose penalties on
        those who do not. Rewards for participation can take many forms:
      </p>
      <ul>
        <li>
          Cash or cash equivalents (e.g., gift cards or gift certificates)
        </li>
        <li>
          Health benefit plan-related rewards (e.g., reductions or waivers in
          employee cost-sharing features, such as premiums, co-payments, or
          deductibles, or additional employer-provided benefits)
        </li>
        <li>
          Other non cash rewards (e.g., employee discounts or merchandise
          prizes).
        </li>
      </ul>

      <p>Penalties of non-participation can take many forms:</p>
      <ul>
        <li>Financial penalties</li>
        <li>Refusal to hire</li>
        <li>Disqualification from the employer’s health plan</li>
        <li>
          Termination of employment (note, however, that HIPAA or ADA issues may
          be raised when a program involves penalties for non-participation).
        </li>
      </ul>
    </InfoDropdown>

    <InfoDropdown title="Disease-Management Program" :headerLvl="2">
      <p>
        An employer-sponsored disease-management program generally identifies
        employees and their family members who have, or who are at risk for
        developing, certain chronic medical conditions. A typical
        disease-management program will identify these individuals and “coach”
        them on how to manage their conditions.
      </p>

      <p>Disease-management programs might provide:</p>
      <ul>
        <li>Evidence-based medical information</li>
        <li>Disease-specific support</li>
        <li>Case monitoring</li>
        <li>
          Coordination of the care and treatment provided by the employer’s
          group health plan.
        </li>
        <li>Monitor laboratory or other medical test results</li>
        <li>
          Provide telephonic, e-mail, or web-based reminders of treatment or
          appointment schedules
        </li>
        <li>Furnish information about minimizing health risks.</li>
      </ul>

      <p>
        Sometimes, these programs offer rewards (such as reductions or waivers
        of employee cost-sharing items, such as premiums, co-payments, or
        deductibles) for employees who participate.
      </p>
    </InfoDropdown>

    <InfoDropdown
      title="How Are Wellness and Disease-Management Programs Alike (and Different)?"
      :headerLvl="2"
    >
      <p>
        In some ways, employer-sponsored wellness and disease-management
        programs may appear quite similar—both types of programs are intended to
        help participants lead healthier lives and lower or control health care
        costs. In fact, sometimes employers combine wellness and
        disease-management programs into a single program, or coordinate
        wellness and disease-management programs with each other or with the
        employer’s other health plans, such as a major medical plan, health FSA,
        HRA, or HSA.
      </p>
      <p>
        But wellness and disease-management programs typically differ from one
        another. Wellness programs generally are designed to help all employees
        become and stay healthier, while disease-management programs are
        targeted toward helping only those employees who are at risk for
        developing, or who have already developed, chronic medical conditions.
      </p>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown: InfoDropdown
  }
};
</script>
